<template>
  <b-modal
      id="true-wallet-modal"
      hide-footer
      size="xl"
      title="ลงทะเบียนระบบออโต้ True Wallet"
  >
    <b-overlay :show="isFetching">
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onRegister)">
          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                  v-slot="{ errors }"
                  name="pin"
                  rules="required|numeric|length:6"
              >
                <b-form-group label="Pin">
                  <b-form-input
                      v-model="form.pin"
                      :state="errors[0] ? false : null"
                      :aria-invalid="false"
                      type="text"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                  v-slot="{ errors }"
                  name="loginToken"
                  rules="required"
              >
                <b-form-group label="Login Token">
                  <b-form-input
                      v-model="form.loginToken"
                      :state="errors[0] ? false : null"
                      :aria-invalid="false"
                      type="text"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider
                  v-slot="{ errors }"
                  name="keyId"
                  rules="required|numeric"
              >
                <b-form-group label="Key ID">
                  <b-form-input
                      v-model="form.keyId"
                      :state="errors[0] ? false : null"
                      :aria-invalid="false"
                      type="text"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                  v-slot="{ errors }"
                  name="tmnId"
                  rules="required">
                <b-form-group label="Tmn ID">
                  <b-form-input
                      v-model="form.tmnId"
                      :state="errors[0] ? false : null"
                      :aria-invalid="false"
                      type="text"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </div>
          </div>
          <div class="text-right">
            <b-button variant="success" type="submit" :disabled="isFetching">
              {{ $t('buttons.submit') }}
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>

</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'TrueWalletModal',
  props: {
    agentBankAccountId: {
      type: String,
      required: true
    },
    trueWalletAccount: {
      type: Object,
      default: () => ({
        pin: '',
        keyId: '',
        loginToken: '',
        tmnId: '',
      })
    }
  },
  data() {
    return {
      isFetching: false,
      form: {}
    }
  },
  created() {
    this.form = {
      pin: this.trueWalletAccount?.pin,
      keyId: this.trueWalletAccount?.keyId,
      loginToken: this.trueWalletAccount?.loginToken,
      tmnId: this.trueWalletAccount?.tmnId,
    }
  },
  methods: {
    ...mapActions(['registerTrueWallet', 'fetchBankAccount']),
    async onRegister() {
      this.isFetching = true
      const result = await this.registerTrueWallet({
        id: this.agentBankAccountId,
        data: this.form,
      })
      if(result) {
        this.$bvModal.hide('true-wallet-modal')
        await this.fetchBankAccount(this.agentBankAccountId)
      }
      this.isFetching = false
    },
  }
}
</script>

<style scoped>

</style>