var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"true-wallet-modal","hide-footer":"","size":"xl","title":"ลงทะเบียนระบบออโต้ True Wallet"}},[_c('b-overlay',{attrs:{"show":_vm.isFetching}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onRegister)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"pin","rules":"required|numeric|length:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pin"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"type":"text"},model:{value:(_vm.form.pin),callback:function ($$v) {_vm.$set(_vm.form, "pin", $$v)},expression:"form.pin"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"loginToken","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Login Token"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"type":"text"},model:{value:(_vm.form.loginToken),callback:function ($$v) {_vm.$set(_vm.form, "loginToken", $$v)},expression:"form.loginToken"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"keyId","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Key ID"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"type":"text"},model:{value:(_vm.form.keyId),callback:function ($$v) {_vm.$set(_vm.form, "keyId", $$v)},expression:"form.keyId"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"tmnId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tmn ID"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"type":"text"},model:{value:(_vm.form.tmnId),callback:function ($$v) {_vm.$set(_vm.form, "tmnId", $$v)},expression:"form.tmnId"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)]),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"success","type":"submit","disabled":_vm.isFetching}},[_vm._v(" "+_vm._s(_vm.$t('buttons.submit'))+" ")])],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }